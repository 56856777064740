<template>
    <InfoCardSlot
      class="insurance-item"
      :hasCustomHeader="true"
      :hasCustomFooter="insuranceData.userVerificationStatus !== UserVerificationStatusEnum.NOT_ADDED"
      actionLabel="Verify Insurance">
      <template #custom-header>
        <Status :status="insuranceData.status"/>
      </template>
      <template #custom-content>
        <div class="insurance-item__content flex-column jc-fs gap-1 h-auto w-100">
          <div class="insurance-item__wrapper flex-column w-100 gap-0">
              <Typography variant="h6">{{ insuranceData?.insurance?.name }}</Typography>
              <Typography variant="custom" textSize=".75rem" textWeight="400">
                {{ $filters.formatToCurrency(insuranceData?.metadata?.sum) }}
              </Typography>
              <Typography variant="custom" textSize=".75rem" textWeight="400">
                {{ $filters.formatToDate(insuranceData?.metadata?.expiryDate) }}
              </Typography>
          </div>
        </div>
      </template>
      <template #custom-footer>
        <div class="flex-row ai-c jc-sb w-100">
          <div class="flex-row ai-c">
            <Icon
              class="cursor-pointer"
              iconName="pdf-icon.svg"
              iconHeight="15px"
              iconWidth="15px"/>
            <Typography
              variant="custom"
              textSize="0.75rem"
              textWeight="500">
                Accreditation.pdf
            </Typography>
          </div>
          <Icon
            @click="onRemoveInsurance(insuranceData.id)"
            class="cursor-pointer"
            iconName="trash-icon.svg"
            iconHeight="15px"
            iconWidth="15px"/>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Status from '@/core/components/common/status/Status.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
// import genericStatus from '@/core/constants/status/generic-status';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Icon,
    Status
  },

  emits: ['on-remove-insurance'],

  props: {
    insuranceData: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  data() {
    return {
      // genericStatus,
      UserVerificationStatusEnum: {
        ...UserVerificationStatusEnum,
      },
    };
  },

  methods: {
    onRemoveInsurance(id) {
      this.$emit('on-remove-insurance', this.normalizeDeleteRequest(id));
    },

    normalizeDeleteRequest(id) {
      const data = {
        insurance: {
          userInsuranceId: id,
          userId: this.user?.id
        }
      };

      return data;
    },
  },
});
</script>
<style lang="scss" scoped>
.insurance-item {
  max-height: unset !important;
  height: auto !important;

  &__content {
  }
  &__wrapper.grid {
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

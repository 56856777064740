<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-insurance flex-column jc-sb">
        <div class="add-insurance__container flex-column">
          <div class="add-insurance__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-insurance__title default-text">{{ isOnEditModal ? 'Edit insurance' : 'Add insurance' }}</h2>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-insurance__body w-100 flex-column">
            <InsuranceForm
              @on-update-value="onUpdateValue"
              @on-taggle-form="onToggleForm"
              :value="userInsurances"
              :hideBackCta="true"/>
            <Button
              :isActive="isFormReady"
              :loading="isSubmitting"
              buttonText="Add insurance"
              @handle-click="onAddInsurance"/>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import InsuranceForm from '@/core/components/common/forms/insurance/InsuranceForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    InsuranceForm,
    Button
  },

  emits: ['on-cancel', 'push-to-insurance-list', 'update-insurance-list', 'on-save-insurance'],

  props: ['show', 'userInsurances', 'isSubmitting'],

  data() {
    return {
      submitting: false,

      isFormReady: false,

      insuranceToBeAdded: null,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  watch: {
  },

  methods: {
    ...mapActions(USERS_STORE, [
    ]),

    initialize() {
    },

    cancel() {
      this.$emit('on-cancel');
    },

    onUpdateValue(insurance) {
      this.isFormReady = Object.keys(insurance).length;
      this.insuranceToBeAdded = insurance;
    },

    onAddInsurance() {
      if (this.isFormReady) {
        this.$emit('on-save-insurance', this.normalizeSaveRequest(this.insuranceToBeAdded));
      }
    },

    normalizeSaveRequest(request) {
      const {
        type, expiryDate, sumCoverage, attachments
      } = request;
      const data = {
        insurance: {
          insuranceId: type,
          userId: this.user.id,
          metadata: {
            sum: sumCoverage,
            expiryDate
          },
          attachments
        }
      };

      return data;
    }
  },
});
</script>
<style lang="scss" scoped>
 .add-insurance {
    min-width: 350px;
    position: relative;
    max-height: 500px;
    height: 100%;

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }
 }
</style>

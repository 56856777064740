<template>
  <div class="passport-insurance-page default-layout-desktop-padding">
    <div class="passport-insurance-page__main-container flex-column h-100">
      <div class="flex-row ai-c jc-sb">
        <div class="flex-column gap-0">
           <div class="flex-row gap-half">
             <Icon
                iconName="insurances.svg"
                customPath="icons/trade-passport-icons"
                iconWidth="20px" iconHeight="20px"/>
             <Typography variant="h6">Your Insurances</Typography>
           </div>
           <div class="flex-row gap-half ai-c cursor-pointer">
              <Typography variant="p" textWeight="500">
                Need trade insurance?&nbsp; <span class="passport-insurance-page__span">Get quotes</span>
              </Typography>
              <Icon iconName="arrow-right-yellow.svg" iconHeight=".8rem" iconWidth=".8rem"/>
            </div>
        </div>
        <Button buttonText="add new insurance" :isActive="true" @click="setInsuranceModal(true)"/>
      </div>
      <el-divider/>
      <div class="passport-insurance-page__content w-100 flex-column">
        <div class="passport-insurance-page__item flex-column w-100">
            <!-- <Typography variant="h6" textWeight="400">{{ item.companyName }}</Typography> -->
            <div class="passport-insurance-page__wrapper w-100 grid">
              <InsuranceItemCard
                v-for="(insurance, index) in availableInsurances" :key="index"
                :insuranceData="insurance"
                @on-remove-insurance="onDeleteInsurance"/>
            </div>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <AddInsuranceModal
      :show="insuranceModal"
      @on-cancel="onCloseModal"
      @on-save-insurance="onSaveInsurance"
      :userInsurances="availableInsurances"
      :isSubmitting="isSubmitting"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import statusType from '@/core/constants/status/type';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import InsuranceItemCard from '@/modules/trades-passport-children/components/card-item/InsuranceItemCard.vue';
import AddInsuranceModal from '@/modules/trades-passport-children/components/modals/AddInsuranceModal.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'TradesPassportOverview',

  components: {
    Typography,
    Icon,
    Button,
    InsuranceItemCard,
    AddInsuranceModal
  },

  data() {
    return {
      UserVerificationStatusEnum: {
        ...UserVerificationStatusEnum,
      },
      isSubmitting: false,
      insuranceModal: false,
      userInsurances: [],
      // data: [
      //   {
      //     companyName: 'Iknowa Ltd',
      //     insuranceData: [
      //       {
      //         status: {
      //           status: genericStatus.REQUIRED_VERIFICATION,
      //           type: statusType.ALERT,
      //           statusName: 'VERIFICATION',
      //           spanText: 'REQUIRED',
      //         },
      //         name: 'Public liability (Zurich)',
      //         coverage: '£5,000,000.00',
      //         expiry: '18 Oct 2023'
      //       },
      //       {
      //         status: {
      //           status: genericStatus.VERIFIED,
      //           type: statusType.VERIFIED,
      //           statusName: 'VERIFIED',
      //         },
      //         name: 'Public liability (Zurich)',
      //         coverage: '£5,000,000.00',
      //         expiry: '18 Oct 2023'
      //       },
      //     ]
      //   },
      //   {
      //     companyName: 'HelloCrossman Ltd',
      //     insuranceData: [
      //       {
      //         status: {
      //           status: UserVerificationStatusEnum.NOT_ADDED,
      //           type: statusType.ALERT,
      //           statusName: 'VERIFICATION',
      //           spanText: 'REQUIRED',
      //         },
      //         name: 'Public liability (Zurich)',
      //         coverage: '£5,000,000.00',
      //         expiry: '18 Oct 2023'
      //       },
      //       {
      //         status: {
      //           status: UserVerificationStatusEnum.NOT_ADDED,
      //           type: statusType.ALERT,
      //           statusName: 'VERIFICATION',
      //           spanText: 'REQUIRED',
      //         },
      //         name: 'Public liability (Zurich)',
      //         coverage: '£5,000,000.00',
      //         expiry: '18 Oct 2023'
      //       },
      //       {
      //         status: {
      //           status: UserVerificationStatusEnum.VERIFIED,
      //           type: statusType.VERIFIED,
      //           statusName: 'VERIFIED',
      //         },
      //         name: 'Public liability (Zurich)',
      //         coverage: '£5,000,000.00',
      //         expiry: '18 Oct 2023'
      //       },
      //     ]
      //   },
      // ],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    availableInsurances() {
      const { userInsurances } = this;

      if (userInsurances.length) {
        return this.normalizeInsurances(userInsurances);
      }

      return [];
    },
  },

  async created() {
    await this.onInitialize();
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'addNewInsurance',
      'getUserInsurances',
      'removeUserInsurance'
    ]),

    async onInitialize() {
      const { user } = this;
      const userId = user.id;
      this.userInsurances = [];

      if (!this.userInsurances.length) {
        await this.setUserInsurances(userId);
      }
    },

    async setUserInsurances(userId) {
      this.userInsurances = await this.getUserInsurances(userId).catch(() => {}) || [];
    },

    setInsuranceModal(state) {
      this.insuranceModal = state;
    },

    onCloseModal(hasSavedData) {
      if (hasSavedData) {
        this.setUserInsurances(this.user.id);
      }
      this.setInsuranceModal(false);
    },

    normalizeInsurances(userInsurances) {
      return userInsurances.map((insurance) => {
        return {
          ...insurance,
          status: this.getStatus(insurance.userVerificationStatus),
        };
      });
    },

    getStatus(verificationState) {
      const statusState = verificationState;

      switch (statusState) {
        case UserVerificationStatusEnum.NOT_ADDED:
          return {
            type: statusType.ALERT,
            statusName: 'VERIFICATION',
            spanText: 'REQUIRED'
          };
        case UserVerificationStatusEnum.PENDING_VERIFICATION:
          return {
            type: statusType.ALERT,
            statusName: 'PENDING',
            spanText: 'VERIFICATION'
          };
        case UserVerificationStatusEnum.VERIFIED:
          return {
            type: statusType.VERIFIED,
            statusName: 'VERIFIED',
          };
        case UserVerificationStatusEnum.REJECTED:
          return {
            type: statusType.ALERT,
            statusName: 'REJECTED',
          };
        default:
          return {
            type: statusType.ALERT,
            statusName: 'VERIFICATION',
            spanText: 'REQUIRED'
          };
      }
    },

    onSaveInsurance(data) {
      this.isSubmitting = true;
      this.addNewInsurance(data.insurance)
        .then(async () => {
          this.$notify.success({
            message: 'Insurance(s) successfully saved.'
          });

          await this.setUserInsurances(data.insurance.userId);
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error submitting insurance details.'
          });
        })
        .finally(() => {
          this.isSubmitting = false;
          this.setInsuranceModal(false);
        });
    },

    onDeleteInsurance(data) {
      this.removeUserInsurance(data.insurance)
        .then(async () => {
          this.$notify.success({
            message: 'Insurance Successfully removed.'
          });

          await this.setUserInsurances(data.insurance.userId);
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error removing insurance.'
          });
        })
        .finally(() => {});
    },

  },
});
</script>

<style lang="scss" scoped>
.el-divider {
    margin: 0;
}
.passport-insurance-page {
  padding-top: 1rem !important;

  &__content {
  }

  &__span {
    color: #FFA500 !important;
    font-weight: 700 !important;
  }

  &__wrapper.grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}
</style>
